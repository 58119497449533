import React, { useCallback } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import classNames from 'classnames'

import { getAltText, getImage } from '../../utils/gatsby-strapi-helpers'

import useMedia from '../../hooks/useMedia'
import { Body, SpecialHeader2 } from '../shared/Text'
import { SecondaryButtonCompact } from '../shared/Buttons'
import Markdown from '../shared/Markdown'

const Header = ({ title, description, ctaUrl, ctaLabel }) => (
  <div className="flex flex-1 flex-col gap-2 lg:gap-8">
    {title && (
      <SpecialHeader2 className="lg:!text-left">{title}</SpecialHeader2>
    )}
    {description && (
      <Body>
        <Markdown>{description}</Markdown>
      </Body>
    )}
    {ctaUrl && (
      <SecondaryButtonCompact
        target="_blank"
        rel="noopener noreferrer"
        href={ctaUrl}
        className="w-fit place-self-center"
      >
        {ctaLabel}
      </SecondaryButtonCompact>
    )}
  </div>
)

const Picture = ({ picture }) => {
  const ref = React.useRef()

  return (
    <div ref={ref} className="relative flex-1">
      <div className="relative mx-auto">
        {picture && (
          <GatsbyImage
            className="rounded"
            image={getImage(picture)}
            alt={getAltText(picture)}
          />
        )}
      </div>
    </div>
  )
}

export default function BigFeature({ feature, withGradient }) {
  const {
    title,
    description,
    ctaUrl,
    ctaLabel,
    invertedLayout: inv,
    picture,
    showInMobile,
  } = feature

  const isMediumRes = useMedia('(min-width: 1024px)')
  const reversible = useCallback(
    (fwd, rev) => ({
      [fwd]: !inv,
      [rev]: inv,
    }),
    [inv]
  )

  return !isMediumRes && !showInMobile ? null : (
    <div className="relative w-screen overflow-hidden py-16 lg:py-32">
      {withGradient && (
        <div className="absolute top-0 h-20 w-full bg-gradient-to-b from-background to-transparent translate-z-0" />
      )}

      <div className="container flex flex-col place-items-center gap-28">
        <div
          className={classNames(
            'flex w-full flex-col justify-between gap-12',
            reversible('lg:flex-row', 'lg:flex-row-reverse')
          )}
        >
          <Picture picture={picture} />
          <Header
            title={title}
            description={description}
            reversible={reversible}
            ctaLabel={ctaLabel}
            ctaUrl={ctaUrl}
          />
        </div>
      </div>
    </div>
  )
}
