import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { find, propEq } from 'ramda'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { SupportHeader } from '../components/sectionHeader'
import HighlightSection from '../components/homeSections/HighlightSection'
import { getImage } from '../utils/gatsby-strapi-helpers'
import { Body, Subtitle } from '../components/shared/Text'
import Markdown from '../components/shared/Markdown'
import BigFeature from '../components/homeSections/BigFeature'
import usePlatforms from '../hooks/usePlatforms'
import LocalizedLink from '../components/localizedLink'

import { messages } from '../hooks/useMessages'

const PcPlatform = ({ name, ...props }) => {
  const { platforms } = usePlatforms()
  const platform = find(propEq('name', name), platforms)
  return (
    <a href={platform.gameUrl}>
      <GatsbyImage
        image={getImage(platform.iconLarge)}
        alt={platform.title}
        {...props}
      />
    </a>
  )
}

const StoPcOffer = ({ pageContext, data }) => {
  const { strapiStoPcOffer } = data

  const {
    title,
    description,
    body,
    packTitle,
    packDetail,
    instructions,
    footer,
    headerImg,
    detailImg,
  } = strapiStoPcOffer

  const seo = {
    metaTitle: title,
    metaDescription: description,
  }

  const feature = {
    title: packTitle,
    description: packDetail,
    picture: detailImg,
    invertedLayout: true,
    showInMobile: true,
  }

  return (
    <Layout pageContext={pageContext}>
      <Seo seo={seo} />
      <HighlightSection
        className="lg:!h-3/4"
        header={<SupportHeader title={title} paragraph={description} />}
        staticImage={
          <GatsbyImage
            image={getImage(headerImg)}
            alt={title}
            layout="fullWidth"
            className="max-h-[70vh]"
            backgroundColor="#161616"
          />
        }
      />

      <div className="container pt-5 lg:max-w-[70vw] lg:pt-14 2xl:max-w-[55vw] 2xl:pt-[2.5rem]">
        <Body>
          <Markdown>{body}</Markdown>
        </Body>
      </div>

      <BigFeature feature={feature} />

      <div className="container pt-5 lg:max-w-[70vw] lg:pt-14 2xl:max-w-[55vw] 2xl:pt-[2.5rem]">
        <Body>
          <Markdown>{instructions}</Markdown>
        </Body>
      </div>

      <div className="container pt-10 text-center lg:max-w-[70vw] lg:pt-14 2xl:max-w-[55vw] 2xl:pt-[2.5rem]">
        <Subtitle>
          <Markdown>{footer}</Markdown>
        </Subtitle>
      </div>
      <div className="container pt-8 text-center lg:max-w-[55vw] lg:pt-14 2xl:max-w-[25vw] 2xl:pt-[2.5rem]">
        <div className="flex w-full flex-col items-center justify-center gap-4 lg:flex-row lg:gap-8">
          <PcPlatform
            name="Steam"
            className="h-[6rem] w-[15rem] xl:w-[20rem]"
            objectFit="contain"
          />
          <div className="flex h-full w-full items-center justify-center text-center">
            <span>OR</span>
          </div>
          <PcPlatform
            name="Epic Games"
            className="h-[6em] w-[10rem] lg:h-[6rem]"
            objectFit="contain"
          />
        </div>
      </div>
      <Body className="pt-28 text-center underline">
        <LocalizedLink to="/">
          <span className="text-heroButton font-normal">
            {messages(pageContext.locale)('back')}
          </span>
        </LocalizedLink>
      </Body>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($locale: String) {
    strapiStoPcOffer(locale: { eq: $locale }) {
      title
      description
      body
      headerImg {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      detailImg {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }

      packTitle
      packDetail
      instructions
      footer
    }
  }
`

export default StoPcOffer
