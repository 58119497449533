import { graphql, useStaticQuery } from 'gatsby'
import { prop, sortBy } from 'ramda'
import React from 'react'

import LocaleContext from '../contexts/localContext'
import { firstNodeForLocale } from '../utils/gatsby-strapi-helpers'

const usePlatforms = () => {
  const {
    allStrapiPlatformsSection: { nodes },
  } = useStaticQuery(graphql`
    query PlatformsQuery {
      allStrapiPlatformsSection {
        nodes {
          title
          platforms {
            sortOrder
            slug
            name
            gameUrl
            iconSmall {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            iconLarge {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
          locale
        }
      }
    }
  `)
  const { locale } = React.useContext(LocaleContext)
  const { title, platforms } = firstNodeForLocale(locale)(nodes)
  return { title, platforms: sortBy(prop('sortOrder'), platforms) }
}

export default usePlatforms
